<template>
  <loader v-bind="{ loading }" text="Loading Equipment">
    <columns>
      <column class="is-narrow">
        <action-button
          :working="downloading"
          @click="downloadEquipmentList"
          class="is-rounded"
          left-icon="download">
          Equipment List
        </action-button>
      </column>
      <column>
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for equipment...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="box" v-for="equipment in equipment.data" :key="equipment.id">
          <columns>
            <column>
              <router-link class="has-text-weight-bold mr-2" :to="{
                name: 'equipment-manager',
                params: {
                    equipment: equipment.uuid
                }}">
                {{ equipment.name }}
            </router-link>
            <small class="has-text-grey">{{ equipment.classification.name }}, Updated {{ equipment.updated_at | asCalendar }}</small>
            </column>
            <column class="is-2 is-flex is-align-items-center is-justify-content-start">
              <icon icon="qrcode"/>
              {{ equipment.code.value }}
            </column>
            <column class="is-2 has-text-right">
                <outcome-tag 
                  :status="equipment.status"
                />
            </column>
          </columns>
        </div>
        <pager v-if="equipment.data.length" :pageable="equipment" context="Equipment" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="equipment.data.length"
          heading="There is no equipment available for display."
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { common as backend } from '@/api'
import { mapGetters } from 'vuex'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    downloading: false,
    filters: {
        search: ''
    },
    equipment: {
      data: []
    },
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadEquipment()
  },

  methods: {
    loadEquipment() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.equipment = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'customer-equipment',
        params: {
          customer: this.$route.params.customer
        },
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        params: {
            customer: this.$route.params.customer
        },
        name: 'customer-equipment',
      })
    },
    downloadEquipmentList() {
      this.downloading = true
      backend.downloadFile(`/v1/customers/${this.$route.params.customer}/equipment/download-xls`, ({ data }) => {
        download(data, `${this.customer.name}-equipment-list.xlsx`, data.type)
        this.downloading = false
      }, () => {
        this.downloading = false
      })
    }
  },

  computed: {
    ...mapGetters('customer', [
      'customer'
    ])
  },

  watch: {
    '$route': 'loadEquipment'
  }

}
</script>